import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { ServiceDescription } from 'views/index/services-section/components/service-description';
import { ServiceTitle } from 'views/index/services-section/components/service-title';
import { CONSTANTS } from 'constants/styles/constants';
import { motion } from 'framer-motion';
import { array, func, number, shape, string } from 'prop-types';

const SServiceTab = styled.div`
    display: flex;
    align-items: center;
    column-gap: 3rem;
    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
        ${({ isActive }) =>
            isActive &&
            css`
                padding-bottom: 1.25rem;
            `}
    `}
`;

const STabButton = styled(motion.button)`
    flex: 1;
    padding: 0 3.125rem;
    align-items: center;
    text-align: right;
    transition: box-shadow 0.5s linear;
    ${({ $isActive }) =>
        $isActive &&
        css`
            height: 15rem;
            box-shadow: 1.563rem 1.25rem 2.188rem -1.25rem rgba(0, 0, 0, 0.08);
        `}
    ${CONSTANTS.MEDIA.max1024`
          height: unset
          box-shadow: none;
          text-align: center;
    `}
`;

export const ServiceTab = ({
    tab: { id, title, description, to, descriptionLinks },
    index,
    activeTab,
    setActiveTab,
}) => {
    const isActive = id === activeTab;
    const { formatMessage } = useIntl();

    return (
        <SServiceTab
            role="tab"
            aria-selected={isActive}
            aria-controls={`tabpanel-${index}`}
        >
            <STabButton
                $isActive={isActive}
                whileFocus={{
                    scale: 1.03,
                }}
                transition={{ duration: 0.5 }}
                type="button"
                id={`tab-${index}`}
                onClick={() => setActiveTab(id)}
            >
                <ServiceTitle
                    title={title}
                    isActive={isActive}
                    className="service-title"
                />
            </STabButton>
            <ServiceDescription
                descriptionLinks={descriptionLinks}
                isActive={isActive}
                role="tabpanel"
                id={`tabpanel-${index}`}
                aria-labelledby={`tabpanel-${index}`}
                description={description}
                to={to}
                arrowLinkAriaLabel={formatMessage({ id: title })}
            />
        </SServiceTab>
    );
};

ServiceTab.propTypes = {
    activeTab: string.isRequired,
    setActiveTab: func.isRequired,
    index: number.isRequired,
    tab: shape({
        id: string,
        title: string,
        description: string,
        descriptionLinks: array,
    }).isRequired,
};
