import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ArrowLink } from 'components/arrow-link';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { motion } from 'framer-motion';
import { useLinkChunks } from 'hooks/use-link-chunks';
import { string } from 'prop-types';

const SWrapper = styled.div`
    position: relative;
    flex: 1;
    overflow: hidden;
    ${CONSTANTS.MEDIA.max1024`
        text-align: center;
    `}
`;

const SArrowLink = styled(ArrowLink)`
    margin-top: 1rem;
`;

export const ServiceDescription = ({
    description,
    descriptionLinks,
    isActive,
    to,
    arrowLinkAriaLabel,
    ...restProps
}) => {
    const { formatMessage } = useIntl();
    const descriptionChunks = useLinkChunks(descriptionLinks);

    return (
        <SWrapper
            style={{
                maxHeight: isActive ? 'fit-content' : '0',
            }}
            transition={{
                duration: 0.3,
            }}
            layout
            {...restProps}
        >
            <motion.div
                initial={{
                    x: '-100%',
                }}
                animate={{
                    x: isActive ? 0 : '-100%',
                }}
                transition={{
                    duration: 0.5,
                }}
            >
                <Paragraph>
                    {formatMessage({ id: description }, descriptionChunks)}
                </Paragraph>
                {to && (
                    <SArrowLink
                        to={to}
                        isActive={isActive}
                        ariaLabel={arrowLinkAriaLabel}
                        withText
                    />
                )}
            </motion.div>
        </SWrapper>
    );
};

ServiceDescription.propTypes = {
    description: string.isRequired,
};
